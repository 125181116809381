@import "lib/bootstrap/scss/bootstrap";
@import "stylesheets/ui/forms";
@import "stylesheets/ui/noticia-item";
@import "stylesheets/ui/noticia-detalhes";
@import "stylesheets/ui/fonts";

body {
  font-family: DeliusUnicase !important;
  position: relative;
  margin-top: 75px;
}

.error {
  color: #c7351f;
}

#noticias-pag {
  padding-top: 30px;
}

.modal {
  font-family: GothamBook;
}

.pagina-interna .subtitulo {
    width: 80%;
    text-align: center;
    margin: 0 auto;
}

#formDoacao {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-up(md) {
    width: 60%
  }
}

.pagina-interna {
  text-align: center;
  font-family: GothamBook;

  .cabecalho {
    height: 280px;
    width: 100%;
    background-position: center;
    background-size: cover;
    @extend .mb-5;
  }
  .titulo {
    font-size: 24pt;
    color: #4E9F09;
    text-align: center;
    font-family: Delius;
  }
  li {
    text-align: left;
    padding: 4px 0px 4px 12px;
  }
  ul {
    margin-top: -4px;
    list-style-image: url('/assets/imgs/lista-item.png');
  }
  h2 {
    font-size: 12pt;
    text-transform: uppercase;
    color: #4E9F09;
    text-align: center;
    font-family: DeliusUnicaseBold;
    @extend .mt-5;
    @extend .mb-4;
  }
  h3 {
    font-size: 12pt;
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
    font-family: GothamBook;
    @extend .mt-4;
    @extend .mb-2;
  }
}

/***************************************************
   NOTÍCIA-DETALHES
***************************************************/



/* mobile */
#topo {
  -webkit-box-shadow: 0px 0px 4px 0px #888;
  -moz-box-shadow: 0px 0px 4px 0px #888;
  box-shadow: 0px 0px 4px 0px #888;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  margin-top: -75px;
  background-color: white;
}

#topo .logo {
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 15px;
  vertical-align: top;
  margin-left: 4px;
  cursor: pointer;
  height: 46px;
  width: 88px;
  background-image: url('/assets/imgs/dignivida-logo-mini.png');
  background-size: cover;
  background-position: center;
}

.menu .redesocial {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #f5c74e;
  display: inline-block;
  margin: 4px 2px 0 8px;
}

.menu .redesocial img {
  width: 30px;
  height: 30px;
}

.menu:nth-of-type(1) {
  margin-bottom: 9px;
}

.menu:nth-of-type(1) .menu-item {
  border-radius: 4px;
  padding: 8px;
  text-align: center;
}

.menu:nth-of-type(2) {
  padding: 10px 0;
  clear: left;
}

.menu:nth-of-type(1) .menu-item:nth-of-type(1) {
  background-color: #eeeeee;
  border-bottom-left-radius: 4px;
}

.menu:nth-of-type(1) .menu-item:nth-of-type(2) {
  background-color: #f5c74e;
}

.menu:nth-of-type(1) .menu-item:nth-of-type(3) {
  background-color: #c7351f;
  border-bottom-right-radius: 4px;
}

.menu:nth-of-type(1) .menu-item:nth-of-type(4) {
}

.menu:nth-of-type(1) .menu-item:nth-of-type(3) a {
  color: white;
}

/* -------------------------------------------------------------------
 * ## menu is open
 * ------------------------------------------------------------------- */
.menu-is-open {
  overflow: hidden;

}
.menu-is-open .menu-barras {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  visibility: visible;
  -webkit-overflow-scrolling: touch;

    z-index: 99999;
    -webkit-box-shadow: 0px 0px 4px 0px #888;
    -moz-box-shadow: 0px 0px 4px 0px #888;
    box-shadow: 0px 0px 4px 0px #888;
}
.menu-is-open .menu-barras .header-nav__content {
  opacity: 1;
  visibility: visible;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  left: 0;
}

.menu-is-open .menu-lateral-overlay {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
}

/* -------------------------------------------------------------------
 * ## mobile menu toggle
 * ------------------------------------------------------------------- */
 .menu-toggle {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   padding: 15px 15px;
 }
.header-menu-toggle {
  background: white;
  border-radius: 4px;
  position: relative;
  width: 48px;
  height: 45px;
  float: right;
  line-height: 45px;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  color: #000;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.header-menu-toggle::before {
  display: inline-block;
  content: "";
  height: 45px;
  left: auto;
  text-align: right;
  padding-left: 15px;
  padding-right: 10px;
  position: absolute;
  top: 0;
  right: 100%;
}
.header-menu-toggle.opaque {
  background-color: #fff;
}
.header-menu-toggle.opaque::before {
  display: none;
}
.header-menu-toggle:hover,
.header-menu-toggle:focus,
.header-menu-toggle:active {
  color: #fcd30c;
}
.header-menu-icon {
  display: block;
  width: 26px;
  height: 2px;
  margin-top: -1px;
  right: auto;
  bottom: auto;
  background-color: black;
  position: absolute;
  left: 11px;
  top: 50%;
}
.header-menu-icon::before,
.header-menu-icon::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: inherit;
  position: absolute;
  left: 0;
}
.header-menu-icon::before {
  top: -9px;
}
.header-menu-icon::after {
  bottom: -9px;
}



/* mobile */
.menu-barras {
  width: 250px;
  padding: 0 24px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  z-index: 9999;
  height: 100%;

  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  visibility: hidden;

  overflow-x: hidden;
  overflow-y: auto;
}

.menu-barras .fechar {
  z-index: 800;
  display: block;
  height: 30px;
  width: 30px;
  border-radius: 3px;
  position: absolute;
  top: 9px;
  right: 24px;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}

.menu-barras .fechar span::before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.menu-barras .fechar span::after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.menu-barras .fechar span::before,
.menu-barras .fechar span::after {
    content: "";
    display: block;
    height: 2px;
    width: 12px;
    background-color: #000;
    position: absolute;
    top: 50%;
    left: 9px;
    margin-top: -1px;
}

.menu:nth-of-type(1) {
  font-size: 10.5pt;
  margin-top: 50px;
}

.menu:nth-of-type(1) a:hover {
  color: black;
  text-decoration: underline;
}

.menu:nth-of-type(2) {
  font-size: 11pt;
  border-top: 1px solid lightgray;
}

.menu-item {
  display: inline-block;
  padding: 0px;
  width: 100%;
  margin-bottom: 4px;
}

.menu:nth-of-type(2) .menu-item {
  padding-top: 9px;
}

#topo > .container > div > div:nth-child(2) > div:nth-child(1) {
  padding-left: 0px;
}

.menu-item a {
  color: black;
}
.menu-item a:hover {
  color: #D70F14;
  text-decoration: none;
}

#slides {
  background-position: center;
  background-size: cover;
  height: 330px;
  position: relative;
  transition: background 0.5s linear;
  -webkit-transition: background 0.5s linear;
  -moz-transition: background 0.5s linear;
}

#slides a {
  color: white;
  text-decoration: none;
}

#slides button {
  cursor: pointer;
}

#slides .backdrop {
  opacity: 0.65;
  background-color: #000000;
  height: 100%;
  width: 100%;
}

#slides .text {
  line-height: 1.2;
  font-size: 19pt;
  color: white;
  text-align: center;
  font-family: Delius;

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -ms-flex: column;
  flex: column;
  -webkit-box-flex: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

#slides .text p {
  margin-top: 14px;
  font-family: GothamBook;
  font-size: 11pt;
}

button {
  background-color: #c7351f;
  border-radius: 100px;
  padding: 6px 18px;
  font-family: Delius;
  border: none;
  color: white;
  font-size: 10pt;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  height: 40px;
}

button.btn-segundo {
  background-color: #f5c74e;
}

button.btn-segundo-outline {
  border: 1px solid #f5c74e;
  background-color: white;
  color: #f5c74e
}

#slides .container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

#slides .swiper-container {
  height: 100%;
  width: 100%;
}


#slides .swiper-container-horizontal>.swiper-pagination-bullets {
  bottom: 32px !important;
}

#slides .swiper-pagination-bullet {
  background: #fff;
  width: 10px;
  height: 10px;
  opacity: 0.4;
}

#slides  .swiper-pagination-bullet-active {
  background-color: white;
  opacity: 1;
}

.secao {
  padding: 46px 0;
  font-family: GothamBook;
}

#quem-somos {
  background-image: url('../imgs/quem-somos-bg.png');
  background-size: cover;
  background-position: center;
  position: relative;
}

#quem-somos img {
  width: 94%;
}

#quem-somos .col-6:nth-of-type(1) {
  padding: 0 32px;
}

#quem-somos p {
  margin-bottom: 2rem;
  line-height: 1.8;
  text-align: justify;
}

#quem-somos p:nth-of-type(1) {
  margin-top:70px;
}

#quem-somos .container {
  margin-top: -49px;
}

.secao-titulo {
  font-size: 19pt;
  color: #4E9F09;
  text-align: center;
  font-family: Delius;
}

.secao-subtitulo {
  margin-top: 16px;
  margin-bottom: 34px;
  text-align: center;
}

#quem-somos-borda {
  background-color: #EECD00;
  background-image: url('../imgs/borda-amarela.png');
  background-repeat: repeat-x;
  height: 13px;
}

#projetos {
  background-color: white;
  margin-top: -16px;
  background-image: url('../imgs/projetos-bg.jpg');
  background-size: cover;
  background-position: center;
}

#projetos .swiper-container {
  margin-top: 40px;
  height: 370px;
  width: 100%;
  padding: 0 80px;
}

#projetos .swiper-container-horizontal>.swiper-pagination-bullets {
  bottom: 0px !important;
}

#projetos .swiper-pagination-bullet {
  background: #fff;
  width: 10px;
  height: 10px;
  opacity: 1;
}

#projetos  .swiper-pagination-bullet-active {
  background-color: #000;
  opacity: 0.7;
}

#projetos .projeto {
    text-align: center;
}

#projetos .projeto .img {
  width: 100%;
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 30px;
}

#projetos .projeto .nome {
  font-family: GothamBold;
  line-height: 1.3;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  color: black;
}

#projetos .projeto .situacao {
  font-family: GothamBold;
  font-size: 11pt;
  color: #4A9F00;
  margin-top: 2px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

#projetos .projeto .ano {
  margin-top: 2px;
  font-size: 11pt;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

#como-ajudar {
  position: relative;
  margin-top: -80px;
  background-image: url('../imgs/como-ajudar-bg.png');
  background-size: cover;
  background-position: center;
  padding-top: 124px;
  padding-bottom: 108px;
}

#como-ajudar .secao-titulo, #como-ajudar .secao-subtitulo {
  color: white;
}

#como-ajudar .quadro {
  margin-bottom: 28px;
  border-radius: 20px;
  padding: 18px 18px 16px 18px;
  text-align: center;
  color: #000;
  max-width: 300px;
  -webkit-box-shadow: 2px 2px 9px 2px #00000005;
  -moz-box-shadow: 2px 2px 9px 2px #00000005;
  box-shadow: 2px 2px 9px 2px #00000005;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

#como-ajudar .quadro2 {
  background-color: #EAC900;
  background-image: url('/assets/imgs/como-ajudar-materiais.png');
  background-size:cover;
  background-position: center;
}

#como-ajudar .quadro1 {
  background-color: #00B4D1;
  background-image: url('/assets/imgs/como-ajudar-trabalho.png');
  background-size:cover;
  background-position: center;
}

#como-ajudar .quadro3 {
  background-color: #69BD2C;
  background-image: url('/assets/imgs/como-ajudar-dinheiro.png');
  background-size:cover;
  background-position: center;
}

#como-ajudar .quadro .titulo {
  text-transform: uppercase;
  font-family: Delius;
  font-size: 16pt;
  padding-bottom: 14px;
  margin-bottom: 10px;
  line-height: 1.1;
  border-bottom: 1px solid #00000011;
}

#como-ajudar .quadro .desc {
  font-family: Delius;
  font-size: 11pt;
  margin-bottom: 14px;
}

#como-ajudar .quadro .mais {
  font-size: 11pt;
  text-transform: uppercase;
  font-family: Delius;
}

#noticias {
  margin-top: -80px;
  background-image: url('../imgs/noticias-bg.jpg');
  background-size: cover;
  background-position: center;
  padding-top: 60px;
  padding-bottom: 20px;
}

#noticias .container {
  margin-top: 40px;
}

#noticias .row:nth-of-type(2) {
  margin-top: 30px;
}

#noticias .ver-tudo {
  width: 100%;
  text-align: center;
}

#noticias .div {
  height: 1px;
  background: lightgray;
}

#parceiros .container {
  margin-top: 60px;
}

#parceiros .col-md-2 {
  text-align: center;
  margin-bottom: 50px;
}

#parceiros .col-6 {
  margin-bottom: 40px;
  text-align: center;
}

#parceiros img {
  max-height: 110px;
  max-width: 100%;
  filter: gray; /* IE */
  -webkit-filter: grayscale(1); /* Old WebKit */
  -webkit-filter: grayscale(100%); /* New WebKit */
  filter: url(resources.svg#desaturate); /* older Firefox */
  filter: grayscale(100%); /* Current draft standard */
  opacity: 0.6;
}

#parceiros img:hover {
  -webkit-filter: grayscale(0);
  filter: none;
  opacity: 1;
}

#rodape {
  background-image: url('../imgs/rodape-borda.png');
  background-position: 0 0;
  background-repeat: repeat-x;
  background-color: #C74B1E;
  font-family: GothamMedium;
  color: white;
  padding-top: 12px;
}

#rodape .row  {
  padding: 24px 0;
}

#rodape .linha1 {
  font-family: GothamBook;
  font-size: 12pt;
  font-weight: bold;
  text-align: center;
}

#rodape .linha1 .col-6:nth-of-type(2) {
  padding-left: 24px;

}

#rodape .linha2, #rodape .linha3 {
  font-family: GothamBook;
  font-size: 10pt;
  text-align: center;
}

#rodape .linha3 {
  padding-top: 0px;
}

#rodape .div {
  height: 1px;
  background-color: white;
  opacity: 0.3;
}

#rodape .selo-bit101 {
  width: 40px;
  height: 40px;
  display: block;
  text-align: center;
  margin: 20px auto;
  margin-bottom: 0;
}

#rodape .icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

#fale-conosco {
  margin-top: -20px;
  padding-top: 0px;
  padding-bottom: 40px;
}

#fale-conosco .container {
  margin-top: 44px;
  padding: 0 30px;
}

#fale-conosco .canal {
  text-align: left;
  margin-bottom: 26px;
}

#fale-conosco .canal .img {
  border-radius: 100%;
  float: left;
  margin-right: 12px;
}

#fale-conosco .canal img {
  width: 50px;
  height: 50px;
}

#fale-conosco .canal .nome {
  margin-bottom: 0px;
  font-family: DeliusUnicaseBold;
  color: #f5c74e;
  font-size: 12pt;
}

.fundo-sol {
  background-image: url('../imgs/fale-conosco-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 289px;
  width: 100%;
  position: absolute;
  bottom: 120px;
  z-index: -999;
  left: 0;
  right: 0;
}

@media (min-width: 1200px) {

  .pagina-interna .subtitulo {
      width: 60%;
      text-align: center;
      margin: 0 auto;
  }


  body {
    font-family: DeliusUnicase !important;
  }

  #topo {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    background-color: white;
  }

  #topo .logo {
    display: inline-block;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 0;
    vertical-align: top;
    cursor: pointer;
    height: 94px;
    width: 130px;
    background-image: url('/assets/imgs/dignivida-logo.png');
    background-size: cover;
    background-position: center;
  }

  .menu .redesocial {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #f5c74e;
    display: inline-block;
    margin: 4px 2px 0 8px;
  }

  .menu .redesocial img {
    width: 30px;
    height: 30px;
  }

  .menu:nth-of-type(1) {
    margin-left: 30px;
    margin-bottom: 9px;
    overflow: auto;
  }

  .menu:nth-of-type(1) .menu-item {
    padding: 7px 62px;
    float: left;
    border-radius: 0px;
    text-align: initial;
  }

  .menu:nth-of-type(2) {
    clear: left;
  }

  .menu:nth-of-type(1) .menu-item:nth-of-type(1) {
    background-color: #eeeeee;
    border-bottom-left-radius: 4px;
  }

  .menu:nth-of-type(1) .menu-item:nth-of-type(2) {
    background-color: #f5c74e;
  }

  .menu:nth-of-type(1) .menu-item:nth-of-type(3) {
    background-color: #c7351f;
    border-bottom-right-radius: 4px;
  }

  .menu:nth-of-type(1) .menu-item:nth-of-type(4) {
    padding: 0 0 0 20px;
  }

  .menu:nth-of-type(1) .menu-item:nth-of-type(3) a {
    color: white;
  }

  .menu-barras {
    float: right;
    height: 94px;
    width: initial;
    vertical-align: top;
    display: inline-block;
    padding: initial;
    position: initial;
    right: initial;
    top: initial;
    bottom: initial;
    background-color: initial;
    z-index: initial;
    -webkit-transition: initial;
    transition: initial;
    -webkit-transform: initial;
    -webkit-backface-visibility: initial;
    -webkit-transform: initial;
    -ms-transform: initial;
    transform: initial;
    visibility: initial;
    overflow-x: initial;
    overflow-y: initial;
  }

  .menu:nth-of-type(1) {
    font-size: 10.5pt;
    margin-top: 0;
  }

  .menu:nth-of-type(1) a:hover {
    color: black;
    text-decoration: underline;
  }

  .menu:nth-of-type(2) {
    font-size: 11pt;
    border-top: none;
  }

  .menu-item {
    display: inline-block;
    padding: 0px 22px;
    width: initial;
    margin-bottom: 0;
  }

  #topo > .container > div > div:nth-child(2) > div:nth-child(1) {
    padding: 7px 62px;
  }

  .menu-item a {
    color: black;
  }
  .menu-item a:hover {
    color: #D70F14;
    text-decoration: none;
  }

  #slides {
    background-position: center;
    background-size: cover;
    height: 600px;
    transition: background 0.5s linear;
    -webkit-transition: background 0.5s linear;
    -moz-transition: background 0.5s linear;
    position: relative;
  }

  #slides .backdrop {
    background-color: #000000bb;
    height: 100%;
    width: 100%;
  }

  #slides .text {
    line-height: 1.2;
    font-size: 30pt;
    color: white;
    text-align: center;
    font-family: Delius;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -ms-flex: column;
    flex: column;
    -webkit-box-flex: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  #slides .text p {
    margin-top: 14px;
    font-family: GothamBook;
    font-size: 14pt;
  }

  button {
    background-color: #c7351f;
    border-radius: 100px;
    padding: 8px 34px;
    font-family: Delius;
    border: none;
    color: white;
    font-size: 12pt;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    height: 40px;
  }

  #slides .content {
    width: 70%;
  }

  #slides .data {
    font-size: 10pt;
    margin-bottom: 12px;
  }

  #slides .container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9999;
  }

  #slides .swiper-container {
    height: 100%;
    width: 100%;
  }

  #slides .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
      background-image: url('../imgs/left-arrow.svg');
      background-size: cover;
      background-position: center;
      height: 70px;
      width: 70px;
      opacity: 0.8;
    }

  #slides .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
      background-image: url('../imgs/right-arrow.svg');
      background-size: cover;
      background-position: center;
      background-size: cover;
      background-position: center;
      height: 70px;
      width: 70px;
      opacity: 0.8;
  }

  #slides .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 58px !important;
  }

  #slides .swiper-pagination-bullet {
    background: #fff;
    width: 10px;
    height: 10px;
    opacity: 0.4;
  }

  #slides  .swiper-pagination-bullet-active {
    background-color: white;
    opacity: 1;
  }

  .secao {
    padding: 74px 0;
    font-family: GothamBook;
  }

  #quem-somos {
    background-image: url('../imgs/quem-somos-bg.png');
    background-size: cover;
    background-position: center;
    position: relative;
  }

  #quem-somos img {
    width: 94%;
  }

  #quem-somos .col-6:nth-of-type(1) {
    padding: 0 32px;
  }

  #quem-somos p {
    margin-bottom: 2rem;
    line-height: 1.8;
  }

  #quem-somos p:nth-of-type(1) {
    margin-top:70px;
  }

  #quem-somos .container {
    margin-top: -49px;
  }

  .secao-titulo {
    font-size: 26pt;
    color: #4E9F09;
    text-align: center;
    font-family: Delius;
  }

  .secao-subtitulo {
    margin-top: 34px;
    margin-bottom: 34px;
    text-align: center;
  }

  #quem-somos-borda {
    background-color: #EECD00;
    background-image: url('../imgs/borda-amarela.png');
    background-repeat: repeat-x;
    height: 13px;
  }

  #projetos {
    background-color: white;
    margin-top: -16px;
    background-image: url('../imgs/projetos-bg.jpg');
    background-size: cover;
    background-position: center;
  }

  #projetos .swiper-container {
    margin-top: 40px;
    height: 370px;
    width: 1200px;
    padding: 0 80px;
  }

  #projetos .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
      background-image: url('../imgs/left-arrow-dark.svg');
      background-size: cover;
      background-position: center;
      height: 70px;
      width: 70px;
      margin-top: -112px;
    }

  #projetos .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
      background-image: url('../imgs/right-arrow-dark.svg');
      background-size: cover;
      background-position: center;
      height: 70px;
      width: 70px;
      margin-top: -112px;
  }

  #projetos .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 0px !important;
  }

  #projetos .swiper-pagination-bullet {
    background: #fff;
    width: 10px;
    height: 10px;
    opacity: 1;
  }

  #projetos  .swiper-pagination-bullet-active {
    background-color: #000;
    opacity: 0.7;
  }

  #projetos .projeto {
      text-align: center;
  }

  #projetos .projeto a {
    text-decoration: none;
  }

  #projetos .projeto .img {
    border: 4px solid transparent;
    width: 210px;
    height: 210px;
    background-position: center;
    background-size: cover;
    border-radius: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
  }


  #projetos .projeto .nome {
    font-family: GothamBold;
    line-height: 1.3;
    width: 210px;
    margin-right: auto;
    margin-left: auto;
  }

  #projetos .projeto .nome:hover {
    color: #c7351f;
  }

  #projetos .projeto .situacao {
    font-family: GothamBold;
    font-size: 11pt;
    color: #4A9F00;
    margin-top: 2px;
    width: 210px;
    margin-right: auto;
    margin-left: auto;
  }

  #projetos .projeto .ano {
    margin-top: 2px;
    font-size: 11pt;
    width: 210px;
    margin-right: auto;
    margin-left: auto;
  }

  #como-ajudar {
    position: relative;
    margin-top: -80px;
    background-image: url('../imgs/como-ajudar-bg.png');
    background-size: cover;
    background-position: center;
    padding-top: 110px;
    padding-bottom: 150px;
  }

  #como-ajudar .secao-titulo, #como-ajudar .secao-subtitulo {
    color: white;
  }

  #como-ajudar .quadro {
    border-radius: 20px;
    padding: 50px 50px 40px 50px;
    text-align: center;
    color: #000;
    max-width: 300px;
    -webkit-box-shadow: 2px 2px 9px 2px #0005;
    -moz-box-shadow: 2px 2px 9px 2px #0005;
    box-shadow: 2px 2px 9px 2px #0005;
    margin-left: auto;
    margin-right: auto;
  }

  #como-ajudar .quadro2 {
    background-color: #EAC900;
  }

  #como-ajudar .quadro1 {
    background-color: #00B4D1;
  }

  #como-ajudar .quadro3 {
    background-color: #69BD2C;
  }

  #como-ajudar .quadro .titulo {
    text-transform: uppercase;
    font-family: Delius;
    font-size: 24pt;
    padding-bottom:14px;
    margin-bottom: 10px;
    line-height: 1.1;
    border-bottom: 1px solid #00000011;
  }

  #como-ajudar .quadro .desc {
    font-family: Delius;
    font-size: 13.5pt;
    margin-bottom: 14px;
  }

  #como-ajudar .quadro .mais {
    font-size: 11pt;
    text-transform: uppercase;
    font-family: Delius;
  }

  #noticias {
    margin-top: -80px;
    background-image: url('../imgs/noticias-bg.jpg');
    background-size: cover;
    background-position: center;
    padding-top: 110px;
    padding-bottom: 20px;
  }

  #noticias .container {
    margin-top: 40px;
  }

  #noticias .row:nth-of-type(2) {
    margin-top: 70px;
  }

  #noticias .ver-tudo {
    width: 100%;
    text-align: center;
  }

  #noticias .div {
    height: 1px;
    background: lightgray;
  }

  #parceiros .container {
    margin-top: 60px;
  }

  #parceiros .col-2 {
    text-align: center;
    margin-bottom: 50px;
  }

  #parceiros img {
    max-height: 110px;
    max-width: 100%;
    filter: gray; /* IE */
    -webkit-filter: grayscale(1); /* Old WebKit */
    -webkit-filter: grayscale(100%); /* New WebKit */
    filter: url(resources.svg#desaturate); /* older Firefox */
    filter: grayscale(100%); /* Current draft standard */
    opacity: 0.6;
  }

  #parceiros img:hover {
    -webkit-filter: grayscale(0);
    filter: none;
    opacity: 1;
  }

  #rodape {
    background-image: url('../imgs/rodape-borda.png');
    background-position: 0 0;
    background-repeat: repeat-x;
    background-color: #C74B1E;
    font-family: GothamMedium;
    color: white;
    padding-top: 12px;
    padding-bottom: 0;
  }

  #rodape .row  {
    padding: 24px 0;
  }

  #rodape .linha1 {
    font-family: GothamBook;
    font-size: 12pt;
    font-weight: bold;
  }

  #rodape .linha1 .col-6:nth-of-type(2) {
    padding-left: 24px;
  }

  #rodape .linha2, #rodape .linha3 {
    font-family: GothamBook;
    font-size: 10pt;
    text-align: center;
  }

  #rodape .linha3 {
    padding-top: 0px;
  }

  #rodape .div {
    height: 1px;
    background-color: white;
    opacity: 0.3;
  }

  #rodape .selo-bit101 {
    width: 40px;
    height: 40px;
  }

  #rodape .icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  #fale-conosco {
    margin-top: -20px;
    padding-top: 0px;
    padding-bottom: 100px;
  }

  #fale-conosco .container {
    margin-top: 44px;
  }

  #fale-conosco .canal {
    text-align: center;
  }

  #fale-conosco .canal .img {
    border-radius: 100%;
    margin-bottom: 12px;
    float: initial;
    margin-right: 0px;
  }

  #fale-conosco .canal img {
    width: 58px;
    height: 58px;
  }

  #fale-conosco .canal .nome {
    margin-bottom: 12px;
    font-family: DeliusUnicaseBold;
    color: #f5c74e;
  }

  .fundo-sol {
    background-image: url('../imgs/fale-conosco-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 1100px;
    width: 100%;
    position: absolute;
    bottom: 100px;
    z-index: -999;
    left: 0;
    right: 0;
  }


}
