@font-face {
  font-family: Delius;
  src: url("/assets/fonts/Delius/Delius-Regular.ttf");
}

@font-face {
  font-family: DeliusUnicase;
  src: url("/assets/fonts/Delius_Unicase/DeliusUnicase-Regular.ttf");
}

@font-face {
  font-family: DeliusUnicaseBold;
  src: url("/assets/fonts/Delius_Unicase/DeliusUnicase-Bold.ttf");
}

@font-face {
  font-family: GothamBook;
  src: url("/assets/fonts/Gotham-Font/GothamBook.ttf");
}

@font-face {
  font-family: GothamMedium;
  src: url("/assets/fonts/Gotham-Font/GothamMedium.ttf");
}

@font-face {
  font-family: GothamBold;
  src: url("/assets/fonts/Gotham-Font/GothamBold.ttf");
}
