input {
  @extend .form-control;
}

form:not(.form-vertical) dl {
  @include make-row();
  @extend .align-items-center;
}

form:not(.form-vertical) dl dt {
  @include make-col-ready();
  @include make-col(12);

  @include media-breakpoint-up(md) {
    @include make-col(2);
  }
}

form:not(.form-vertical) dl dd {
  @include make-col-ready();
  @include make-col(12);

  @include media-breakpoint-up(md) {
    @include make-col(10);
  }
}