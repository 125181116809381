
.noticia-detalhes {
    font-family: GothamBook;
    text-align: center;

    h1, h2, h3, h4, h5, h6, h7 {
        @extend .mt-5;
        @extend .mb-4;
        text-align: left;
        font-weight: bold;
    }

    h1 { font-size: 22pt; }
    h2 { font-size: 18pt; }
    h3 { font-size: 14pt; }
    h4 { font-size: 12pt; }
    h5 { font-size: 10pt; }
    h6 { font-size: 8pt; }
    h7 { font-size: 4pt; }

    ul, ol, li {
        text-align: left !important;
        text-align-last: left !important;
    }

    blockquote {
        text-align: left !important;
        margin: 0 0 1rem;
        text-align-last: left !important;
        border-left: 4px solid black;
        padding-left: 8px;
        font-style: italic;
        padding: 4px;
        margin-left: 28px;

        @include media-breakpoint-up(md) {
            padding: 8px;
            border-left: 8px solid black;
            margin-left: 64px;
        }
    }

    .capa {
        border-radius: 4px;
        height: 266px;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-color: #eee;

        margin-right: auto;
        margin-left: auto;
        @extend .my-4;

        @include media-breakpoint-up(md) {
            height: 340px;
            width: 600px;
        }
    }

    .titulo {
        font-size: 17pt;
        font-family: GothamBold;
        line-height: 24pt;
        width: 100%;
        margin-right: auto;
        margin-left: auto;

        @include media-breakpoint-up(md) {
            font-size: 26pt;
            line-height: 33pt;
            width: 80%;
        }
    }

    .subtitulo {
        font-size: 12pt;
        margin-bottom: 12px;
        width: 100%;
        margin-right: auto;
        margin-left: auto;

        @include media-breakpoint-up(md) {
            font-size: 13pt;
            width: 80%;
        }
    }

    .autor {
        margin-top: 22px;

        .nome {
            font-size: 11pt;
            font-family: GothamBold;
        }
    }

    .data {
        font-size: 10pt;
        color: #c7351f
    }

    .texto {
        margin-top: 22px;
        font-size: 12pt;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        text-align: justify;

        @include media-breakpoint-up(md) {
            font-size: 13pt;
            width: 80%;
        }

        .img-container {
            text-align: center;
            width: 100%;

             img {
                 border-radius: 4px;
                 height: auto;
                 width: 100%;
                 background-size: cover;
                 background-position: center;
                 background-color: #eee;

                 margin-right: auto;
                 margin-left: auto;
                 @extend .my-4;

                 @include media-breakpoint-up(md) {
                     height: auto;
                     width: 100%;
                 }
             }
        }
    }
}
