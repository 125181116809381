.noticia-item {
  margin-bottom: 24px;
}

.noticia-item .capa {
  float: left;
  width: 110px;
  height: 110px;
  margin-right: 12px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  border-radius: 4px;
  border-bottom: 4px solid transparent;

  @include media-breakpoint-up(md) {
    height: 200px;
    float: initial;
    width: initial;
    margin-right: 0;
  }
}

.noticia-item .capa:hover {
  border-bottom: 4px solid #c7351f;
}

.noticia-item .titulo {
  font-size: 12pt;
  font-family: GothamBold;
  margin-top: 0px;
  line-height: 1.2;

  @include media-breakpoint-up(md) {
    font-size: 14pt;
    margin-top: 18px;
    line-height: 1.5;
  }
}

.noticia-item .titulo a {
    color: #212529;
}

.noticia-item .titulo a:hover {
    color: #c7351f;
    text-decoration: none;
}

.noticia-item .subtitulo {
  margin: 10px 0;
  font-size: 10pt;
  font-family: GothamBook;

  @include media-breakpoint-up(md) {
    margin: 18px 0;
    font-size: 12pt;
  }
}

.noticia-item .data {
  font-size: 10pt;
  font-family: GothamBook;

  @include media-breakpoint-up(md) {
    font-size: 11pt;
  }
}

